import { useState } from "react";

import { Position, Orientation, ImageLoading, getUrlForAssets } from "../../Contracts";

import Image from "../../Common/Image";

import styles from "./Card.module.scss";

export const Card = (props) => {

  const { orientation, imagePosition: position, alignment } = props;

  const [videoPosterClicked, setVideoPosterClicked] = useState(false);

  const formatClassName = (className) => {
    if (!className) {
      return '';
    }
    return className.split('-').join(' ').split(' ').map((word) => word[0].toUpperCase() + word.substring(1)).join('');
  };

  const toggleVideoPosterClicked = () => {
    setVideoPosterClicked(!videoPosterClicked);
  };

  const getCardPositionClasses = (position) => {
    if (!position || (!position.desktop && !position.mobile)) {
      return null;
    }
    let cardPositionClasses = '';
    if (position.desktop === Position.RIGHT) {
      cardPositionClasses += ` ${styles.CardContainerRightDesktop}`;
    } else if (position.desktop === Position.LEFT) {
      cardPositionClasses += ` ${styles.CardContainerLeftDesktop}`;
    }
    if (position.mobile === Position.RIGHT) {
      cardPositionClasses += ` ${styles.CardContainerRightMobile}`;
    } else if (position.mobile === Position.LEFT) {
      cardPositionClasses += ` ${styles.CardContainerLeftMobile}`;
    }
    return cardPositionClasses;
  };

  const getCardOrientationClasses = (orientation) => {
    if (orientation === Orientation.HORIZONTAL) {
      return styles.CardContainerHorizontal;
    }
    return styles.CardContainerVertical;
  };

  const getCardAlignmentClasses = (alignment) => {
    if (!alignment || (!alignment.alignItems && !alignment.justifyContent)) {
      return null;
    }
    let cardAlignmentClasses = '';
    if (alignment.alignItems) {
      cardAlignmentClasses += ` ${styles[`CardContainerAlignItems${formatClassName(alignment.alignItems)}`]}`;
    }
    if (alignment.justifyContent) {
      cardAlignmentClasses += ` ${styles[`CardContainerJustifyContent${formatClassName(alignment.justifyContent)}`]}`;
    }
    return cardAlignmentClasses;
  };

  const renderCardImage = (cardImage, onClick) => {
    return cardImage ? (
      <Image
        src={cardImage.videoPosterUrl ? getUrlForAssets(cardImage.videoPosterUrl) : getUrlForAssets(cardImage.imageUrl)}
        fallbackSrc={cardImage.videoPosterUrlFallback ? getUrlForAssets(cardImage.videoPosterUrlFallback) : getUrlForAssets(cardImage.imageUrlFallback)}
        alt={cardImage.imageAlt || "card-image"}
        height={cardImage.videoPosterHeight ? cardImage.videoPosterHeight : (cardImage.imageHeight ? cardImage.imageHeight : '100%')}
        width={cardImage.videoPosterWidth ? cardImage.videoPosterWidth : (cardImage.imageWidth ? cardImage.imageWidth : '100%')}
        className={styles.CardImage}
        loading={cardImage.imageLoading === ImageLoading.EAGER ? ImageLoading.EAGER : ImageLoading.LAZY}
        style={onClick ? { cursor: 'pointer' } : {}}
        onClick={onClick}
      />
    ) : null;
  };

  const renderCardVideo = (cardVideo) => {
    if (!cardVideo) {
      return null;
    }
    return cardVideo.videoTagName === 'video' ? (
      <video
        src={getUrlForAssets(cardVideo.videoUrl)}
        title={cardVideo.videoAlt || "card-video"}
        height={cardVideo.videoHeight ? cardVideo.videoHeight : '100%'}
        width={cardVideo.videoWidth ? cardVideo.videoWidth : '100%'}
        style={{
          height: '100%',
          maxHeight: cardVideo.videoHeight ? cardVideo.videoHeight : '100%',
          width: '100%',
          maxWidth: cardVideo.videoWidth ? cardVideo.videoWidth : '100%'
        }}
        playsInline={true}
        muted={cardVideo.videoMuted}
        autoPlay={cardVideo.videoAutoplay}
        controls={cardVideo.videoControls}
        controlsList="nodownload"
        disablePictureInPicture={cardVideo.videoDisablePictureInPicture}
        allowFullScreen={cardVideo.videoAllowFullScreen}
        loop={cardVideo.videoLoop}
        className={`${styles.CardImage} ${styles.CardVideo}`}
        preload={cardVideo.videoLoading === ImageLoading.EAGER ? "auto" : "none"}
      />
    ) : (
      <iframe
        src={cardVideo.videoUrl}
        title={cardVideo.videoAlt || "card-video"}
        name={cardVideo.videoAlt || "card-video"}
        height={cardVideo.videoHeight ? cardVideo.videoHeight : '100%'}
        width={cardVideo.videoWidth ? cardVideo.videoWidth : '100%'}
        style={{
          height: cardVideo.videoHeight ? cardVideo.videoHeight : '100%',
          width: cardVideo.videoWidth ? cardVideo.videoWidth : '100%'
        }}
        frameBorder="0"
        allow="camera; microphone; geolocation; display-capture; ambient-light-sensor; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; xr-spatial-tracking;"
        allowFullScreen={true}
        referrerPolicy="strict-origin-when-cross-origin"
        className={`${styles.CardImage} ${styles.CardVideo}`}
        loading={cardVideo.videoLoading === ImageLoading.EAGER ? ImageLoading.EAGER : ImageLoading.LAZY}
      />
    );
  };

  const renderCardImageContainer = (cardImage) => {
    return cardImage ? (
      <aside id={cardImage.id} className={`${styles.CardImageContainer} ${cardImage.videoUrl ? styles.CardVideoContainer : ''} ${cardImage.className ? cardImage.className : ''}`}>
        {cardImage.videoUrl ? (
          (cardImage.videoPosterUrl && !videoPosterClicked) ? (
            renderCardImage(cardImage, toggleVideoPosterClicked)
          ) : (
            renderCardVideo(cardImage)
          )
        ) : (
          renderCardImage(cardImage)
        )}
      </aside>
    ) : null;
  };

  const renderCardContent = (cardContent) => {
    return (cardContent && (cardContent.title || cardContent.description)) ? (
      <aside id={cardContent.id} className={`${styles.CardContent} ${cardContent.className ? cardContent.className : ''}`}>
        {cardContent.title && <div className={`${styles.CardTitle} ${cardContent.titleClassName ? cardContent.titleClassName : ''}`}>{cardContent.title}</div>}
        {cardContent.description && <div className={`${styles.CardDescription} ${cardContent.descriptionClassName ? cardContent.descriptionClassName : ''}`}>{cardContent.description}</div>}
      </aside>
    ) : null;
  };

  const cardOrientationClasses = getCardOrientationClasses(orientation);
  const cardPositionClasses = getCardPositionClasses(position);
  const cardAlignmentClasses = getCardAlignmentClasses(alignment);

  return (
    <div
      id={props.id}
      className={`${styles.CardContainer} ${props.className ? props.className : ''} ${cardOrientationClasses ? cardOrientationClasses : ''} ${cardPositionClasses ? cardPositionClasses : ''} ${cardAlignmentClasses ? cardAlignmentClasses : ''}`}
      style={props.style ? props.style : {}}
    >
      {props.cardImage && renderCardImageContainer(props.cardImage)}
      {props.cardContent && renderCardContent(props.cardContent)}
    </div>
  );

};

export default Card;
