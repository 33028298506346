import { useEffect, useState } from "react";

import { ImageLoading, getMergedQueryParamsUrl, capitalize, getUrlForAssets } from "../../Contracts";

import styles from "./Footer.module.scss";

export const Footer = (props) => {

  const { footerData = {}, showLinksColumn = true } = props;

  const [browserWindow, setBrowserWindow] = useState({});

  useEffect(() => {
    setBrowserWindow(window);
  }, []);

  const renderBrandLogoImage = (brandSection) => {
    if (!brandSection || !brandSection.cardImageUrl) {
      return null;
    }
    return (
      <img src={getUrlForAssets(brandSection.cardImageUrl)} alt="brand logo" className={styles.FooterLogoImage} loading={ImageLoading.LAZY} height={brandSection.cardImageHeight || "100%"} width={brandSection.cardImageWidth || "100%"} />
    );
  };

  const renderBrandLogo = (brandSection) => {
    if (!brandSection) {
      return null;
    }
    return (
      <div className={styles.FooterLogoContainer}>
        {(brandSection.linkUrl) ? (
          <a href={getMergedQueryParamsUrl(brandSection.linkUrl, browserWindow)} target={brandSection.openInNewTab ? '_blank' : '_self'} rel={brandSection.openInNewTab ? 'noopener noreferrer' : ''} className={styles.FooterLogoLink}>
            {renderBrandLogoImage(brandSection)}
          </a>
        ) : renderBrandLogoImage(brandSection)}
      </div>
    );
  };

  const renderBrandDescription = (cardDescription) => {
    if (!cardDescription) {
      return null;
    }
    return (
      <div className={styles.FooterBrandDescription}>{cardDescription}</div>
    );
  };

  const renderBrandContacts = (contactsList = []) => {
    return (
      <div className={styles.FooterBrandContacts}>
        {contactsList.map((contact, index) => {
          return (
            <div key={index} className={styles.FooterBrandContact}>
              {(contact.title && contact.title.length > 0) && (
                <div className={styles.FooterBrandContactTitle}>{contact.title}</div>
              )}
              {(contact.email && contact.email.length > 0) && (
                <div className={styles.FooterBrandContactEmail}>
                  <a href={`mailto:${contact.email}`} target="_blank" rel="noopener noreferrer nofollow">{contact.email}</a>
                </div>
              )}
              {(contact.number && contact.number.length > 0) && (
                <div className={styles.FooterBrandContactNumber}>
                  <a href={`tel:${contact.number}`} rel="noopener noreferrer nofollow">{contact.number}</a>
                </div>
              )}
              {(contact.address && contact.address.length > 0) && (
                <div className={styles.FooterBrandContactAddress}>{contact.address}</div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderBrandColumn = (brandSection) => {
    if (!brandSection) {
      return null;
    }
    return (
      <section className={`${styles.FooterBrandColumn} ${styles.FooterColumn}`}>
        {brandSection && renderBrandLogo(brandSection)}
        {brandSection.cardDescription && renderBrandDescription(brandSection.cardDescription)}
        {brandSection.contactsList && renderBrandContacts(brandSection.contactsList)}
      </section>
    );
  };

  const renderB2bEcommerceBrandsColumn = (brandSection, linksList = []) => {
    if (!brandSection) {
      return null;
    }
    return (
      <section className={`${styles.FooterBrandColumn} ${styles.FooterColumn}`}>
        {brandSection && renderBrandLogo(brandSection)}
        {brandSection.cardDescription &&
          renderBrandDescription(brandSection.cardDescription)}
        <div className={styles.FooterBrandLinksContainer}>
          {linksList.map((item, index) => {
            return (
              <div key={index} className={styles.FooterBrandLinkItem}>
                <a href={item.url} rel="noopener noreferrer nofollow">{item.text}</a>
              </div>
            );
          })}
        </div>
        <div className={styles.FooterBrandsCopyrightContainer}>
          {brandSection.sectionSubTitle}
        </div>
      </section>
    );
  }

  const renderLinkColumns = (featuresList) => {
    if (!featuresList || featuresList.length === 0) {
      return null;
    }
    return featuresList.slice(0, 4).map((section, index) => {
      return (
        <nav key={index} className={`${styles.FooterLinksColumn} ${styles.FooterColumn}`}>
          {section.cardTitle && <div className={styles.FooterLinksHeader}>{section.cardTitle}</div>}
          {(section.linksList && section.linksList.length > 0) && (
            <ul className={styles.FooterLinkContainer} itemType="http://www.schema.org/SiteNavigationElement" itemScope={true}>
              {section.linksList.map((link, index) => {
                return (
                  <li key={index} itemProp="name">
                    {link.url ? (
                      <a className={styles.FooterLink} href={getMergedQueryParamsUrl(link.url, browserWindow)} target={link.openInNewTab ? '_blank' : '_self'} rel={link.openInNewTab ? 'noopener noreferrer' : ''} itemProp="url">
                        {capitalize(link.text)}
                      </a>
                    ) : (
                      <span className={styles.FooterLink}>{capitalize(link.text)}</span>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </nav>
      );
    });
  };

  return (
    <footer className={styles.FooterContainer}>
      {!showLinksColumn ? (
        <div
          className={`${styles.FooterBrandContainer} quicksell-footer page-container-padding`}
        >
          {footerData.brandSection && footerData.brandSection.linksList && (
            <aside
              className={`${styles.FooterBrandColumnItem} ${styles.FooterColumnContainer}`}
            >
              {renderB2bEcommerceBrandsColumn(footerData.brandSection, footerData.brandSection.linksList)}
            </aside>
          )}
        </div>
      ) : (
        <div
          className={`${styles.FooterColumns} quicksell-footer page-section-padding`}
        >
          {footerData.brandSection && (
            <aside
              className={`${styles.FooterBrandColumnContainer} ${styles.FooterColumnContainer}`}
            >
              {renderBrandColumn(footerData.brandSection)}
            </aside>
          )}
          {footerData.linksSection &&
            footerData.linksSection.featuresList &&
            footerData.linksSection.featuresList.length > 0 && (
              <aside
                className={`${styles.FooterLinksColumnContainer} ${styles.FooterColumnContainer}`}
              >
                {renderLinkColumns(footerData.linksSection.featuresList)}
              </aside>
            )}
        </div>
      )}
    </footer>
  );

};

export default Footer;
